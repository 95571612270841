$(function() {
    // Disable next and previous arrows if current item is the last slide
    $('[data-wrap="false"]').each(function(index, carousel) {
        $(carousel).on('slid.bs.carousel', function () {
            let leftCarouselArrow = $(carousel).find('.left.carousel-control');
            let rightCarouselArrow = $(carousel).find('.right.carousel-control');
            
            let fistCarouselSlide = $(carousel).find('.item:first-child');
            if(fistCarouselSlide.hasClass('active')) {
                $(leftCarouselArrow).addClass('disabled');
            } else {
                $(leftCarouselArrow).removeClass('disabled');
            }
            
            let lastCarouselSlide = $(carousel).find('.item:last-child');
            if(lastCarouselSlide.hasClass('active')) {
                $(rightCarouselArrow).addClass('disabled');
            } else {
                $(rightCarouselArrow).removeClass('disabled');
            }
        });
    });
});